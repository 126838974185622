export default function shuffle(array) {
  const return_arry = [...array]
    let currentIndex = return_arry.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [return_arry[currentIndex], return_arry[randomIndex]] = [
        return_arry[randomIndex], return_arry[currentIndex]];
    }
  
    return return_arry;
  }
import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import { GolazoProgressEnum } from '../GolazoProgress';
import { BaseGameProps, PlayerCareerRow } from '../../types';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import GameFooter from '../GameFooter';


interface HandlePlayerSearchProps {
    players: String[]
    setSelectedPlayer: Function
}

function HandlePlayerSearch(props: HandlePlayerSearchProps) {
    const playerOptions = props.players.map((player, indx) => ({'id': indx, 'name': player}))
    return (
        <ReactSearchAutocomplete 
            items={playerOptions}
            className='player-search'
            onSelect={(item) => props.setSelectedPlayer(item.name)}
            />
    )
}

export interface OverTheirCareerProps extends BaseGameProps {
    playerOptions: string[]
    player: string
    careerRows: PlayerCareerRow[]

}

function OverTheirCareer(props: OverTheirCareerProps) {
    return (
        <div className='game-container'>
            <GameTitle title="Over Their Career" />
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{margin: 'auto', minWidth: '33vw'}}>
                    <HandlePlayerSearch players={props.playerOptions} setSelectedPlayer={(selectedPlayer: string) => props.setProgress(selectedPlayer === props.player ? GolazoProgressEnum.Correct : GolazoProgressEnum.Wrong)} />
                </div>
                <div style={{margin: 'auto'}}>
                <table style={{minWidth: '33vw', marginTop: '5vh'}}>
                    <thead style={{fontWeight: 600, fontSize: '1.5em'}}>
                        <tr>
                            <td>Club</td>
                            <td>Year</td>
                            <td>Apps</td>
                            <td>Goals</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.careerRows.map((row, indx) => {
                            return(<tr key={indx}>
                                <td>{row.club}</td>
                                <td>{row.year}</td>
                                <td>{row.apps}</td>
                                <td>{row.goals}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
                </div>
            </div>
            <div style={{ marginTop: '2vh', flexDirection: 'row', alignContent: 'space-between', display: props.status === GolazoProgressEnum.Unanswered ? 'none': 'flex' }}>
                <div style={{margin: 'auto', fontSize: '3em', color: props.status === GolazoProgressEnum.Correct ? 'green' : 'red'}}>{props.player}</div>
            </div>
            <div style={{ flexDirection: 'row', alignContent: 'space-around', display: props.status === GolazoProgressEnum.Unanswered ? 'none': 'flex' }}>
                <GameFooter showAnswers={undefined} continue={() => props.gotoNext()} />
            </div>
        </div>
    );
}

export default OverTheirCareer;

import React from 'react';
import './App.css';
import GolazoProgress, { GolazoProgressEnum, GolazoProgressArry } from './components/GolazoProgress';
import GameOn from './components/games/GameOn';
import MockQuestionService from './utils/mockService';
import OverTheirCareer from './components/games/OverTheirCareer';
import LieGame from './components/games/Lies';
import ArchRivals from './components/games/ArchRivals';
import Zonal, { ZonalProps } from './components/games/Zonal';
import OverAndUnder from './components/games/OverAndUnder';
import GameResults from './components/GameResults';
import { ArchRivalsQuestion, GameOnQuestion, LieQuestion, OverAndUnderQuestion, OverTheirCareerQuestion, QuestionService, ZonalQuestion } from './types';
import WebQuestionService from './utils/WebQuestionService';
import DaysDisplay from './components/DaysDisplay';

const defaultState: GolazoProgressArry = [GolazoProgressEnum.Unanswered, GolazoProgressEnum.Unanswered, GolazoProgressEnum.Unanswered, GolazoProgressEnum.Unanswered, GolazoProgressEnum.Unanswered, GolazoProgressEnum.Unanswered]

function App() {
  const [golazoProgress, setGolazoProgress]: [GolazoProgressArry, Function] = React.useState([...defaultState])
  const [activeDay, setActiveDay]: [number, Function] = React.useState(-1)
  const [currentGame, setCurrentGame]: [number, Function] = React.useState(0)
  

  const QUESTION_SERVICE: QuestionService = WebQuestionService
  const [daysAvailable, setDaysAvailable]: [number, Function] = React.useState(-1)
  const [maybeGameOn, setMaybeGameOn]: [undefined | GameOnQuestion, Function]  = React.useState(undefined)
  const [maybeOtc, setMaybeOtc]: [undefined | GameOnQuestion, Function]  = React.useState(undefined)

  const [maybeLie, setMaybeLie]: [undefined | LieQuestion, Function]  = React.useState(undefined)
  const [maybeArch, setMaybeArch]: [undefined | ArchRivalsQuestion, Function]  = React.useState(undefined)
  const [maybeZonal, setMaybeZonal]: [undefined | ZonalQuestion, Function]  = React.useState(undefined)
  const [maybeOU, setMaybeOU]: [undefined | OverAndUnderQuestion , Function]  = React.useState(undefined)

  React.useEffect(() => {
    if (daysAvailable !== -1) {
      return
    } else {
      QUESTION_SERVICE.getCountOfDaysAvailable().then((data: number) => {
        setDaysAvailable(data); 
        if (window.location.hash.includes('#day-')) {
          let day = parseInt(window.location.hash.replace("#day-", ""))
          QUESTION_SERVICE.setDay(day)
          setActiveDay(day)
        } else {
          setActiveDay(data); 
          QUESTION_SERVICE.setDay(data)
        }
      })
    }
  }, [])
  React.useEffect(() => {
    if (activeDay !== -1) {
      QUESTION_SERVICE.getGameOnQuestion().then((data) => setMaybeGameOn(data))
      QUESTION_SERVICE.getOverTheirCareerQuestion().then((data) => setMaybeOtc(data))
      QUESTION_SERVICE.getLieQuestion().then((data) => setMaybeLie(data))
      QUESTION_SERVICE.getArchRivalsQuestion().then((data) => setMaybeArch(data))
      QUESTION_SERVICE.getZonalQuestion().then((data) => setMaybeZonal(data))
      QUESTION_SERVICE.getOverAndUnderQuestion().then((data) => setMaybeOU(data))
      setGolazoProgress([...defaultState])
    }
  }, [activeDay])

  const handleUserAnswerProgress = (progress: GolazoProgressEnum, i: number) => {
    const newGolazoProgress = [...golazoProgress]
    newGolazoProgress[i] = progress
    setGolazoProgress(newGolazoProgress)
  }

  const goToNext = (i: number) => {
    if(i < golazoProgress.length) {
      setCurrentGame(i+1)
    }
  }

  const getActiveGameScreen = () => {
    if (currentGame === -1 && daysAvailable !== -1) {
      return <DaysDisplay daysAvailable={daysAvailable} setDay={(day: number) => {QUESTION_SERVICE.setDay(day).then(() => {setActiveDay(day); setCurrentGame(0)})}}  />
    }
    if (currentGame === 0 && maybeGameOn !== undefined) {
      return <GameOn setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 0)}} gotoNext={() => goToNext(0)} status={golazoProgress[0]} {...maybeGameOn as GameOnQuestion}/>
    }
    if (currentGame === 1 && maybeOtc !== undefined) {
      return <OverTheirCareer setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 1)}} gotoNext={() => goToNext(1)} status={golazoProgress[1]} {...maybeOtc as OverTheirCareerQuestion}/>
    }
    if (currentGame === 2 && maybeLie !== undefined) {
      return <LieGame setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 2)}} gotoNext={() => goToNext(2)} status={golazoProgress[2]} {...maybeLie as LieQuestion}/>
    }
    if (currentGame === 3 && maybeArch !== undefined) {
      return <ArchRivals setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 3)}} gotoNext={() => goToNext(3)} status={golazoProgress[3]} {...maybeArch as ArchRivalsQuestion}/>
    }
    if (currentGame === 4 && maybeZonal !== undefined) {
      return <Zonal setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 4)}} gotoNext={() => goToNext(4)} status={golazoProgress[4]} {...maybeZonal as ZonalQuestion}/>
    }
    if (currentGame === 5 && maybeOU !== undefined) {
      return <OverAndUnder setProgress={(progress: GolazoProgressEnum) => {handleUserAnswerProgress(progress, 5)}} gotoNext={() => goToNext(5)} status={golazoProgress[5]} {...maybeOU as OverAndUnderQuestion}/>
    }
    if (currentGame === 6) {
      return (<GameResults progress={golazoProgress} />)
    }
    return (<div>Uh oh...</div>)
  }


  return (
    <div className="App">
      <GolazoProgress progress={golazoProgress} setCurrentGame={setCurrentGame} />
      {getActiveGameScreen()}
      <footer className='footer'>
        <button onClick={() => setCurrentGame(-1)}>Day #{activeDay} - Play Another Day</button>
        <span>Questions by <a href="https://www.youtube.com/watch?v=u-KqDVNowKU">Marge</a> & <a href="https://www.youtube.com/watch?v=u-KqDVNowKU">Moloney</a></span>
        <span>Developed by <a href="https://fradl.dev">Fraz</a></span>
      </footer>
    </div>
  );
}

export default App;

import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import { GolazoProgressEnum } from '../GolazoProgress';
import { BaseGameProps, OverAndUnderQuestion } from '../../types';
import GameFooter from '../GameFooter';

export interface OverAndUnderProps extends BaseGameProps, OverAndUnderQuestion {}

interface OverAndUnderOptionProps {
    optionValue: string
    onAnswer: Function
    className: string
}

function OverAndUnderOption(props: OverAndUnderOptionProps) {
    return (
        <button className={props.className} onClick={() => props.onAnswer()}>{props.optionValue}</button>
    )
}

const MAX_ROUNDS = 2

function OverAndUnder(props: OverAndUnderProps) {
    const [currentRound, setCurrentRound]: [number, Function] = React.useState(0)
    
    const handleRoundSelection = (roundIndx: number, option: string) => {
        if (props.answers[currentRound] !== option) {
            props.setProgress(GolazoProgressEnum.Wrong)
        }
        else if (props.status === GolazoProgressEnum.Unanswered && currentRound === MAX_ROUNDS) {
            props.setProgress(GolazoProgressEnum.Correct)
        }
        setCurrentRound(Math.min(currentRound + 1, MAX_ROUNDS+1))
    }

    const renderRounds = () => {
        let roundIndx = 0;
        let rounds = []
        while (roundIndx <= MAX_ROUNDS && roundIndx <= currentRound) {
            rounds.push(
                <div key={roundIndx} style={{display: 'flex', justifyItems: 'space-between'}}>
                    {
                props.questions[roundIndx].map((opt: string) => {
                    let className = 'game-button mod'
                    if (currentRound !== roundIndx) {
                        if (opt === props.answers[roundIndx]) {
                            className += ` correct-answer`
                        } else {
                            className += ` incorrect-answer`
                        }
                        opt = opt.replace("{{", "")
                        opt = opt.replace("}}", "")
                    } else {
                        const reg = /({{.*}})/
                        opt = opt.replace(reg, "")
                    }
                    return(
                        <OverAndUnderOption key={opt} optionValue={opt} className={className} onAnswer={() => handleRoundSelection(roundIndx, opt)} />
                    )
                })}
                </div>
            )
            roundIndx += 1
        }
        return rounds
    }
    return (
        <div className='game-container'>
            <GameTitle title="Over and Under" />
            <div style={{color: '#37F3FF', fontSize: '1.3rem'}}>Theme: {props.theme}</div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {renderRounds()}
            </div>
            <div style={{ flexDirection: 'row', alignContent: 'space-around', display: props.status === GolazoProgressEnum.Unanswered || currentRound-1 !== MAX_ROUNDS ? 'none': 'flex' }}>
                <GameFooter showAnswers={undefined} continue={() => props.gotoNext()} />
            </div>
        </div>
    );
}

export default OverAndUnder;

import React from 'react';

export interface GameFooterProps {
    showAnswers: undefined | Function
    continue: () => void
}

function GameFooter(props: GameFooterProps) {
  React.useEffect(() => {
    let elem = document.getElementById('continue-button')
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  })
  return (
    <>
    {props.showAnswers !== undefined ? <button style={{margin: 'auto', marginRight: '4vw', marginTop: '5vh'}} className='game-button' onClick={() => props.showAnswers!()}>Show Answers</button> : null }
    <button style={{margin: 'auto', marginTop: '5vh'}} id='continue-button' className='game-button' onClick={props.continue}>Continue</button>
    </>
  );
}

export default GameFooter;

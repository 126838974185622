import React from 'react';
import '../App.css'

export interface DaysDisplayProps {
    setDay: Function
    daysAvailable: number
}

function DaysDisplay(props: DaysDisplayProps) {
    const daybuttons = []
    let i = 0
    while (i < props.daysAvailable) {
        daybuttons.push(
            i
        )
        i += 1
    }
  return (
    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '6em', gap: '1em', marginLeft: '1rem', marginRight: '1rem'}}>
        {daybuttons.map((_, i) => {
            return(
                <button key={i+1} className='day-button' onClick={() =>props.setDay(i+1)}>{i+1}</button>
            )
        })}
    </div>
  );
}

export default DaysDisplay;

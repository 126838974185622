import React from 'react';
import './GolazoProgress.css'

export enum GolazoProgressEnum
 {
    'Correct'=1,
    'Wrong'=-1,
    'Unanswered'=0
}

interface GolazoLetterProps {
    letter: string
    state: GolazoProgressEnum
    onClick?: Function
}

function GolazoLetter(props: GolazoLetterProps) {
    let stateClass = ""
    let style: React.CSSProperties = {}
    if (props.onClick) {
        style = {
            cursor: 'pointer'
        }
    }
    switch(props.state) {
        case GolazoProgressEnum.Correct:
            stateClass = 'letter-correct'
            break;
        case GolazoProgressEnum.Wrong:
            stateClass = 'letter-wrong'
            break;
        case GolazoProgressEnum.Unanswered:
            stateClass = 'letter-unanswered'
            break;
    }
    return(
        <span className={stateClass} style={style} onClick={() => props.onClick ? props.onClick() : null}>{props.letter}</span>
    )
}

export type GolazoProgressArry = [GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum]

export interface GolazoProgressProps {
    progress: GolazoProgressArry
    setCurrentGame: Function
}

function GolazoProgress(props: GolazoProgressProps) {
    const letters = ['G', 'O', 'L', 'A', 'Z', 'O']
  return (
    <div className='letter-container'>
      {props.progress.map((progress, index) => <GolazoLetter key={index} letter={letters[index]} state={progress} onClick={() => props.setCurrentGame(index)} />)}
    </div>
  );
}

export default GolazoProgress;

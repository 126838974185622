import React from 'react';
import { GolazoProgressEnum } from './GolazoProgress';
import '../App.css'

export interface GameResultsProps {
    progress: [GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum, GolazoProgressEnum]
}

function handleResult(res: GolazoProgressEnum, key: string) {
    if (res === 0) return <div key={key}>〰</div>
    if (res === -1) return <div key={key} style={{color: 'red'}}>✗</div>
    if (res === 1) return <div key={key} style={{color: 'green'}}>✔</div>
}

function handleResultName(name: string, res: GolazoProgressEnum) {
    if (res === 0) return <div key={name}>{name}</div>
    if (res === -1) return <div key={name}><span style={{color: 'red'}}>{name.substring(0, 1)}</span>{name.substring(1, name.length)}</div>
    if (res === 1) return <div key={name}><span style={{color: 'green'}}>{name.substring(0, 1)}</span>{name.substring(1, name.length)}</div>
}

function GameResult(props: {result: GolazoProgressEnum, name: string}) {
    return (
        <div key={props.name} style={{display: 'flex', justifyContent: 'center'}} className='result'>
            <div style={{flexBasis: '10em', textAlign: 'left'}}>{handleResultName(props.name, props.result)}</div>
            <div>{handleResult(props.result, props.name)}</div>
        </div>
    )
}

function GameResults(props: GameResultsProps) {
    const gameNames = [
        'Game On',
        'Over Their Career',
        'Lies',
        'Arch Rivals',
        'Zonal',
        'Over & Under',
    ]
  return (
    <div className="fade-in" style={{marginTop: '5rem', fontSize: '2rem'}}>
        {gameNames.map((name, indx) => <GameResult key={name} name={name} result={props.progress[indx]} />)}
        <button className='premier-button' style={{
            border: 'none',
            padding: '1em',
            margin: '1rem',
            backgroundColor: '#F90B6A',
            minWidth: '30vw',
            color: 'black',
        }}
        onClick={() => {
            if (!window.isSecureContext) {
                return
            }
            const results = Array.from(document.getElementsByClassName('result')).map((x) => (x as any)['innerText'].replace("\n", ": "))
            const text = results.join("\n")
            console.log(text)
            navigator.share({title: 'GOLAZO Results', text})
        }}
        >Share Result</button>
        <button style={{
            border: 'none',
            padding: '1em',
            margin: '1rem',
            backgroundColor: '#F90B6A',
            minWidth: '30vw',
            color: 'black',
        }}
        className='premier-button'
        onClick={() => {
            if (!window.isSecureContext) {
                return
            }
            const results = Array.from(document.getElementsByClassName('result')).map((x) => (x as any)['innerText'].replace("\n", ": "))
            const text = results.join("\n")
            console.log(text)
            navigator.clipboard.writeText('My Golazo Results Today!\n' + text)
        }}
        >Copy Result</button>
    </div>
  );
}

export default GameResults;

import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import shuffle from '../../utils/shuffle';
import { GolazoProgressEnum } from '../GolazoProgress';
import GameFooter from '../GameFooter';
import { BaseGameProps } from '../../types';

interface LieOptionProps {
    optionValue: string
    onAnswer: Function
    className: string
}

function LieOption(props: LieOptionProps) {
    return (
        <button className={props.className} onClick={() => props.onAnswer()}>{props.optionValue}</button>
    )
}

export interface LiesProps extends BaseGameProps {
    wrongAnswers: [string, string]
    correctAnswer: string
}

function LieGame(props: LiesProps) {
    const options: string[] = (shuffle([...props.wrongAnswers, props.correctAnswer]) as string[])
    const [optionsState, _]: [string[], Function] = React.useState([...options])
    return (
        <div className='game-container'>
            <GameTitle title="Lies" />
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{fontSize: '1.75em'}}>
                    Two Truths, One Lie - Pick The Lie
                </div>
                <div style={{display: 'flex', flexDirection: 'column',  margin: 'auto'}}>
                {optionsState.map((opt: string) => {
                    let className = 'game-button'
                    if (props.status !== GolazoProgressEnum.Unanswered) {
                        if (opt === props.correctAnswer) {
                            className += ` correct-answer`
                        } else {
                            className += ` incorrect-answer`
                        }
                    }
                    return(
                        <LieOption key={opt} optionValue={opt} className={className} onAnswer={() => props.setProgress(opt === props.correctAnswer ? GolazoProgressEnum.Correct : GolazoProgressEnum.Wrong)} />
                    )
                }
                    
                )}
                </div>
            </div>
            <div style={{ flexDirection: 'row', alignContent: 'space-around', display: props.status === GolazoProgressEnum.Unanswered ? 'none': 'flex' }}>
                <GameFooter showAnswers={undefined} continue={() => props.gotoNext()} />
            </div>
        </div>
    );
}

export default LieGame;

import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import shuffle from '../../utils/shuffle';
import { GolazoProgressEnum } from '../GolazoProgress';
import GameFooter from '../GameFooter';
import { BaseGameProps } from '../../types';

interface LieOptionProps {
    optionValue: string
    onAnswer: Function
    className: string
}

function GameOnOption(props: LieOptionProps) {
    return (
        <button key={props.optionValue} className={props.className} onClick={() => props.onAnswer()}>{props.optionValue}</button>
    )
}

export interface LiesProps extends BaseGameProps {
    question: string
    wrongAnswers: [string, string, string]
    correctAnswer: string
}

function GameOn(props: LiesProps) {
    const [optionsState, setOptionsState]: [string[], Function] = React.useState([...shuffle([...props.wrongAnswers, props.correctAnswer]) as string[]])
    React.useEffect(() => {
        setOptionsState([...shuffle([...props.wrongAnswers, props.correctAnswer]) as string[]])
    }, [props.wrongAnswers, props.correctAnswer])

    return (
        <div className='game-container'>
            <GameTitle title="Game On" />
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{color: 'rgb(55, 243, 255)', fontSize: '1.3rem'}}>{props.question}</div>
                <div style={{display: 'flex', flexDirection: 'column',  margin: 'auto'}}>
                {optionsState.map((opt: string) => {
                    let className = 'game-button'
                    if (props.status !== GolazoProgressEnum.Unanswered) {
                        if (opt === props.correctAnswer) {
                            className += ` correct-answer`
                        } else {
                            className += ` incorrect-answer`
                        }
                    }
                    return(
                        <GameOnOption key={opt} optionValue={opt} className={className} onAnswer={() => props.setProgress(opt === props.correctAnswer ? GolazoProgressEnum.Correct : GolazoProgressEnum.Wrong)} />
                    )
                }
                    
                )}
                </div>
            </div>
            <div style={{ flexDirection: 'row', alignContent: 'space-around', display: props.status === GolazoProgressEnum.Unanswered ? 'none': 'flex' }}>
                <GameFooter showAnswers={undefined} continue={() => props.gotoNext()} />
            </div>
        </div>
    );
}

export default GameOn;

import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import shuffle from '../../utils/shuffle';
import { GolazoProgressEnum } from '../GolazoProgress';
import GameFooter from '../GameFooter';
import { ArchRivalsQuestion, BaseGameProps, LieQuestion } from '../../types';

export interface ArchRivalsProps extends BaseGameProps, ArchRivalsQuestion {}

function ArchRivals(props: ArchRivalsProps) {
    const options: string[] = (shuffle([...props.teamTwoAnswers, ...props.teamOneAnswers, ...props.sharedAnswers]) as string[])
    const [commonSelectedOptions, setCommonSelectedOptions]: [string[], Function] = React.useState([...options])
    const [teamOneSelectedOptions, setTeamOneSelectedOptions]: [string[], Function] = React.useState([])
    const [teamTwoSelectedOptions, setTeamTwoSelectedOptions]: [string[], Function] = React.useState([])
    const [selectedOption, setSelectedOption]: [string, Function] = React.useState('')
    const [selectedTeam, setSelectedTeam]: [number, Function] = React.useState(-1)

    React.useEffect(() => {
        if (selectedOption !== '' && selectedTeam !== -1) {
            const newCommonAnswers = [...commonSelectedOptions].filter((opt) => opt !== selectedOption)
            const newTeamOneAnswers = [...teamOneSelectedOptions].filter((opt) => opt !== selectedOption)
            const newTeamTwoAnswers = [...teamTwoSelectedOptions].filter((opt) => opt !== selectedOption)
            switch(selectedTeam) {
                case 0: {
                    newTeamOneAnswers.push(selectedOption)
                    break;
                }
                case 1: {
                    newCommonAnswers.push(selectedOption)
                    break;
                }
                case 2: {
                    newTeamTwoAnswers.push(selectedOption)
                    break;
                }
            }
            setTeamOneSelectedOptions(newTeamOneAnswers)
            setCommonSelectedOptions(newCommonAnswers)
            setTeamTwoSelectedOptions(newTeamTwoAnswers)
            setSelectedOption('')
            setSelectedTeam(-1)
        }
    }, [selectedOption, selectedTeam])

    const handleLockIn = () => {
        const t1 = props.teamOneAnswers.map((answer) => teamOneSelectedOptions.includes(answer))
        const com = props.sharedAnswers.map((answer) => commonSelectedOptions.includes(answer))
        const t2 = props.teamTwoAnswers.map((answer) => teamTwoSelectedOptions.includes(answer))
        const total = [...t1, ...com, ...t2]
        if (total.find((x) => x === false) === false) {
            props.setProgress(GolazoProgressEnum.Wrong)
        } else {
            props.setProgress(GolazoProgressEnum.Correct)
        }
        
    }

    const showAnswers = () => {
        setTeamOneSelectedOptions(props.teamOneAnswers)
        setCommonSelectedOptions(props.sharedAnswers)
        setTeamTwoSelectedOptions(props.teamTwoAnswers)
    }

    const column_styling: React.CSSProperties = {
        display: 'flex', 
        flexDirection:'column', 
        minWidth: '20%', 
        marginLeft: '1rem',
        marginRight: '1rem',
        paddingRight: '1rem', 
        paddingLeft: '1rem', 
        borderRadius: '1rem', 
        paddingTop: '2em',
        paddingBottom: '2em',

        backgroundColor: selectedOption !== '' ? 'rgba(0,0,0,0.5)' : undefined
}

    const renderTeamButton = (indx: number, label: string) => {
        let className = "team-button"
        if (selectedTeam === indx) {
            className += ' team-button-selected'
        } else if (selectedTeam !== -1) {
            className += ' team-button-not-selected'
        }
        return(
            <button className='team-button' onClick={() => setSelectedTeam(indx)}>{label}</button>
        )
    }

    const renderOption = (index: number, option: string) => {
        let className = 'selectable'
        if (selectedOption === option) {
            className += ' selected'
        }
        if (props.status !== GolazoProgressEnum.Unanswered) {
            if (props.sharedAnswers.includes(option)) {
                className += " correct-answer"
            } else {
                className += " incorrect-answer"
            }
        }
        return(
            <button
                key={index}
                style={{marginLeft: '1em', flexBasis: '30%', marginTop: '1em', border: 'none', color: 'white', display: option === '' ? 'none' : ''}} 
                className={className}
                onClick={(e) => {
                    e.stopPropagation(); 
                    setSelectedOption(option)
                }
                    }>
                    {option}
                </button>
        )
    }

    return (
        <div className='game-container'>
            <GameTitle title="Arch Rivals" />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                {renderTeamButton(0, props.teamOne)}
                {renderTeamButton(1, props.commonalityLabel)}
                {renderTeamButton(2, props.teamTwo)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <div style={column_styling} onClick={() => {setSelectedTeam(0)}}>
                    {teamOneSelectedOptions.map((option, index) => {
                        return(renderOption(index, option))
                    })}
                </div>
                <div style={column_styling} onClick={() => {setSelectedTeam(1)}}>
                {commonSelectedOptions.map((option, index) => {
                    return(renderOption(index, option))
                })}
                </div>
                <div style={column_styling} onClick={() => {setSelectedTeam(2)}}>
                    {teamTwoSelectedOptions.map((option, index) => {
                        return(renderOption(index, option))
                    })}
                </div>
                
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-around' }}>
                {props.status === GolazoProgressEnum.Unanswered ? 
                <button style={{margin: 'auto', marginTop: '5vh'}} className='game-button' onClick={handleLockIn}>Lock In</button>
                :
                <GameFooter showAnswers={showAnswers} continue={() => props.gotoNext()} />
                }
            </div>
        </div>
    );
}

export default ArchRivals;

import React from 'react';

function GameTitle(props: {title: string}) {
  return (
    <div style={{fontSize: '4rem', color: '#727272'}}>
        {props.title}
    </div>
  );
}

export default GameTitle;

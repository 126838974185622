import { GameOnQuestion, QuestionService, OverTheirCareerQuestion, LieQuestion, ArchRivalsQuestion, ZonalQuestion, OverAndUnderQuestion, DailyQuestionSet } from '../types'


const BASE_URL = "https://api.golazo.fradl.dev"
let todays_question_set: DailyQuestionSet | undefined = undefined
let target_question_number = -1


async function get_questions_for_day(): Promise<DailyQuestionSet> {
  if (todays_question_set !== undefined) {
    return Promise.resolve(todays_question_set)
  }
  const resp = await fetch(BASE_URL+"/day/"+target_question_number)
  const data = await resp.json()
  todays_question_set = data
  return data
}

async function setDay(day: number): Promise<boolean> {
  target_question_number = day - 1
  todays_question_set = undefined
  await get_questions_for_day()
  return Promise.resolve(true)
}

async function getCountOfDaysAvailable(): Promise<number> {
  return Promise.resolve(10)
}

async function getGameOnQuestion(): Promise<GameOnQuestion> {
  const questions = await get_questions_for_day()
  return questions['game_on']
}

async function getLieQuestion(): Promise<LieQuestion> {
  const questions = await get_questions_for_day()
  return questions['lies']
}

async function getArchRivalsQuestion(): Promise<ArchRivalsQuestion> {
    const questions = await get_questions_for_day()
    return questions['arch_rivals']
}

async function getZonalQuestion(): Promise<ZonalQuestion> {
    const questions = await get_questions_for_day()
    return questions['zonal']
}

async function getOverAndUnderQuestion(): Promise<OverAndUnderQuestion> {
    const questions = await get_questions_for_day()
    return questions['over_and_under']
}


async function getOverTheirCareerQuestion(): Promise<OverTheirCareerQuestion> {
    const questions = await get_questions_for_day()
    return questions['over_their_career']
}

const WebQuestionService: QuestionService = {
    getGameOnQuestion: getGameOnQuestion,
    getOverTheirCareerQuestion: getOverTheirCareerQuestion,
    getLieQuestion: getLieQuestion,
    getArchRivalsQuestion: getArchRivalsQuestion,
    getZonalQuestion: getZonalQuestion,
    getOverAndUnderQuestion: getOverAndUnderQuestion,
    getCountOfDaysAvailable: getCountOfDaysAvailable,
    setDay: setDay,

}


const all: DailyQuestionSet = {
  "arch_rivals": {
    "commonalityLabel": "COMMONALITY",
    "sharedAnswers": [
      "ANSWER 1b",
      "ANSWER 2b",
      "ANSWER 3b"
    ],
    "teamOne": "TEAM 1",
    "teamOneAnswers": [
      "ANSWER 1a",
      "ANSWER 2a but like a really long answer for 2a that takes up plenty of space for testing",
      "ANSWER 3a"
    ],
    "teamTwo": "TEAM 2",
    "teamTwoAnswers": [
      "ANSWER 1c",
      "ANSWER 2c",
      "ANSWER 3c"
    ]
  },
  "game_on": {
    "correctAnswer": "Manchester United",
    "question": "Which English club was the first to win the European Cup in 1968",
    "wrongAnswers": [
      "Nottingham Forest",
      "Tottenham Hotspur",
      "Aston Villa"
    ]
  },
  "lies": {
    "correctAnswer": "Wayne Rooney has won six Premier League titles",
    "wrongAnswers": [
      "Wayne Rooney is Manchester United all time leading goal scorer",
      "Wayne Rooney scored a hat-trick on his Manchester United debut at 18 years old"
    ]
  },
  "over_and_under": {
    "answers": [
      "Ethihad {{55,017}}",
      "Ethihad {{55,017}}",
      "Old Trafford {{74,879}}"
    ],
    "questions": [
      [
        "Ethihad {{55,017}}",
        "Anfield {{54,074}}"
      ],
      [
        "Old Trafford {{74,879}}",
        "Ethihad {{55,017}}"
      ],
      [
        "Tottenham Hotspur {{62,850}}",
        "Old Trafford {{74,879}}"
      ]
    ],
    "theme": "Capacity"
  },
  "over_their_career": {
    "careerRows": [
      {
        "apps": "40",
        "club": "Malm\u00f6 FF",
        "goals": "16",
        "year": "1999-2001"
      },
      {
        "apps": "74",
        "club": "Ajax",
        "goals": "35",
        "year": "2001-2004"
      },
      {
        "apps": "70",
        "club": "Juventus",
        "goals": "23",
        "year": "2004-2006"
      },
      {
        "apps": "88",
        "club": "Inter Milan",
        "goals": "57",
        "year": "2006-2009"
      },
      {
        "apps": "29",
        "club": "Barcelona",
        "goals": "16",
        "year": "2009-2011"
      },
      {
        "apps": "29",
        "club": "AC Milan(loan)",
        "goals": "14",
        "year": "2010-2011"
      },
      {
        "apps": "32",
        "club": "AC Milan",
        "goals": "28",
        "year": "2011-2012"
      },
      {
        "apps": "122",
        "club": "PSG",
        "goals": "113",
        "year": "2012-2016"
      },
      {
        "apps": "33",
        "club": "Man United",
        "goals": "17",
        "year": "2016-2018"
      },
      {
        "apps": "56",
        "club": "LA Galaxy",
        "goals": "52",
        "year": "2018-2019"
      },
      {
        "apps": "64",
        "club": "AC Milan",
        "goals": "34",
        "year": "2020-2023"
      }
    ],
    "player": "Zlatan Ibrahimovic",
    "playerOptions": [
      "Zlatan Ibrahimovic",
      "Radamel Falcao",
      "Robin van Persie",
      "Yaya Toure",
      "Edinson Cavani",
      "Sergio Aguero",
      "Iker Casillas",
      "Neymar",
      "Sergio Busquets",
      "Xabi Alonso",
      "Thiago Silva",
      "Mesut Ozil",
      "David Silva",
      "Bastian Schweinsteiger",
      "Gianluigi Buffon",
      "Luis Suarez",
      "Sergio Ramos",
      "Vincent Kompany",
      "Gerard Pique",
      "Philipp Lahm",
      "Willian",
      "Marco Reus",
      "Franck Ribery",
      "Manuel Neuer",
      "Ashley Cole",
      "Wayne Rooney",
      "Juan Mata",
      "Thomas Muller",
      "Mario G\u00f6tze",
      "Karim Benzema",
      "Cesc Fabregas",
      "Oscar",
      "Fernandinho",
      "Javier Mascherano",
      "Gareth Bale",
      "Javier Zanetti",
      "Daniele De Rossi",
      "Dani Alves",
      "Petr Cech",
      "Mats Hummels",
      "Carles Puyol",
      "Angel Di Maria",
      "Carlos Tevez",
      "Didier Drogba",
      "Giorgio Chiellini",
      "Marcelo",
      "Stephan El Shaarawy",
      "Toni Kroos",
      "Samuel Eto\u2019o",
      "Jordi Alba",
      "Mario Gomez",
      "Arturo Vidal",
      "Eden Hazard",
      "James Rodriguez",
      "Marouane Fellaini",
      "Ramires",
      "David Villa",
      "Klaas Jan Huntelaar",
      "Nemanja Vidic",
      "Joe Hart",
      "Arjen Robben",
      "Mario Balotelli",
      "Mathieu Valbuena",
      "Pierre-Emerick Aubameyang",
      "Robert Lewandowski",
      "Hernanes",
      "Pedro",
      "Santi Cazorla",
      "Christian Eriksen",
      "Ezequiel Lavezzi",
      "Joao Moutinho",
      "Mario Mand\u017euki\u0107",
      "Patrice Evra",
      "David Luiz",
      "Luka Modric",
      "Victor Wanyama",
      "Mapou Yanga-M'Biwa",
      "Hulk",
      "Darijo Srna",
      "Emmanuel Mayuka",
      "John Terry",
      "Kwadwo Asamoah",
      "Leonardo Bonucci",
      "Javier Pastore",
      "Henrikh Mkhitaryan",
      "Moussa Dembele",
      "Hatem Ben Arfa",
      "Samir Nasri",
      "Shinji Kagawa",
      "Wesley Sneijder",
      "Pepe",
      "Marek Hamsik",
      "Javi Martinez",
      "Diego Forlan",
      "Paulinho",
      "Olivier Giroud",
      "Shaqiri",
      "Nicolas Anelka",
      "Pele",
      "Cristiano Ronaldo",
      "Lionel Messi",
      "Diego Maradona",
      "Franz Beckenbauer",
      "Michel Platini",
      "Johan Cruyff",
      "Ronaldo",
      "Marco van Basten",
      "Eusebio",
      "Gerd Muller",
      "Bobby Charlton",
      "Karl-Heinz Rummenigge",
      "Zinedine Zidane",
      "Luisito Suarez",
      "Lothar Matthaus",
      "Ruud Gullit",
      "Lev Yashin",
      "Andriy Shevchenko",
      "Raymond Kopa",
      "Gianni Rivera",
      "Bobby Moore",
      "Franco Baresi",
      "Rivaldo",
      "Luis Figo",
      "Florian Albert",
      "Denis Law",
      "Michael Owen",
      "Roberto Baggio",
      "Hristo Stoichkov",
      "Ferenc Puskas",
      "Ronaldinho",
      "Paolo Rossi",
      "Kaka",
      "Alfredo Di Stefano",
      "Pavel Nedved",
      "Jean-Pierre Papin",
      "Oleg Blokhin",
      "Garrincha",
      "Kevin Keegan",
      "George Best",
      "Allan Simonsen",
      "Omar Sivori",
      "Josef Masopust",
      "Matthias Sammer",
      "Fabio Cannavaro",
      "Romario",
      "Mario Kempes",
      "Zico",
      "George Weah",
      "Stanley Matthews",
      "Igor Belanov",
      "Paolo Maldini",
      "Jurgen Klinsmann",
      "Kenny Dalglish",
      "David Beckham",
      "Thierry Henry",
      "Andres Iniesta",
      "Dino Zoff",
      "John Charles",
      "Raul",
      "Roberto Carlos",
      "Gabriel Batistuta",
      "Giacinto Facchetti",
      "Gheorghe Hagi",
      "Frank Lampard",
      "Gary Lineker",
      "Paul Breitner",
      "Ian Rush",
      "Dennis Bergkamp",
      "Preben Elkjaer",
      "Eric Cantona",
      "Deco",
      "Gordon Banks",
      "Davor Suker",
      "Rob Rensenbrink",
      "Bernd Schuster",
      "Luigi Riva",
      "Gunter Netzer",
      "Alessandro Mazzola",
      "Alain Giresse",
      "Hans Krankl",
      "Helmut Rahn",
      "Dejan Savicevic",
      "Paulo Futre",
      "Predrag Mijatovic",
      "Jean Tigana",
      "Jairzinho",
      "Rivelino",
      "Giuseppe Meazza",
      "Didi",
      "Billy Wright",
      "Salvatore Schillaci",
      "Darko Pancev",
      "Xavi",
      "Michael Laudrup",
      "Zbigniew Boniek",
      "Dragan Dzajic",
      "Steven Gerrard",
      "Alan Shearer",
      "Peter Schmeichel",
      "Ruud Krol",
      "Fernando Torres",
      "Jimmy Greaves",
      "Uwe Seeler",
      "Kazimierz Deyna",
      "Frank Rijkaard",
      "Oliver Kahn",
      "Karl-Heinz Schnellinger",
      "Just Fontaine",
      "Amancio Amaro",
      "Emilio Butragueno",
      "Antoine Griezmann",
      "Andreas Brehme",
      "Paul Gascoigne",
      "Ivo Viktor",
      "Johnny Haynes",
      "Roger Milla",
      "Jari Litmanen",
      "Duncan Edwards",
      "Jimmy Johnstone",
      "Jose Luis Chilavert",
      "Claudio Caniggia",
      "Tostao",
      "Tom Finney",
      "Ryan Giggs",
      "Samuel Eto'o",
      "Alessandro Del Piero",
      "Rinat Dasayev",
      "Ruud van Nistelrooy",
      "Francisco Gento",
      "Kevin De Bruyne",
      "Paul Scholes",
      "Patrick Vieira",
      "Marcel Desailly",
      "Lilian Thuram",
      "Francesco Totti",
      "Andrea Pirlo",
      "Harry Kane",
      "Michael Ballack",
      "Berti Vogts",
      "Kylian Mbappe",
      "Christian Vieri",
      "Peter Shilton",
      "Ferenc Bene",
      "Rudi Voller",
      "Fabien Barthez",
      "Bryan Robson",
      "Johan Neeskens",
      "Edwin van der Sar",
      "Thibaut Courtois",
      "Mario Gotze",
      "Paul Pogba",
      "Hugo Lloris",
      "Miroslav Klose",
      "David Seaman",
      "Sadio Mane",
      "Jimmy Floyd Hasselbaink",
      "Edin Dzeko",
      "Andy Cole",
      "David de Gea",
      "Ian Wright",
      "Neville Southall",
      "Michael Essien",
      "Jan Ceulemans",
      "Dimitar Berbatov",
      "Wolfgang Overath",
      "Dwight Yorke",
      "Jamie Vardy",
      "David Trezeguet",
      "Sami Hyypia",
      "Emmanuel Adebayor",
      "Patrick Kluivert",
      "John Barnes",
      "William Gallas",
      "Grzegorz Lato",
      "Diego",
      "Joe Cole",
      "Jens Lehmann",
      "Fernando Hierro",
      "Robert Pires",
      "Gianluca Vialli",
      "Dimitri Payet",
      "Roberto Firmino",
      "Roy Keane",
      "Hernan Crespo",
      "Mario Mandzukic",
      "David Platt",
      "Gianfranco Zola",
      "Lukas Podolski",
      "Mohamed Salah",
      "Peter Beardsley",
      "Tomas Brolin",
      "Alexis Sanchez",
      "Paul McGrath",
      "Philippe Coutinho",
      "Tim Cahill",
      "Emmanuel Petit",
      "Park Ji-Sung",
      "Florent Malouda",
      "Rio Ferdinand",
      "Paul Van Himst",
      "Riyad Mahrez",
      "Harry Kewell",
      "Mark Hughes",
      "Sol Campbell",
      "Nwankwo Kanu",
      "Kurt Hamrin",
      "Tibor Nyilasi",
      "Horst Szymaniak",
      "Pirri",
      "Marius Tresor",
      "Andreas Moller",
      "Tomas Rosicky",
      "Claude Makelele",
      "Thiago Alcantara",
      "Rafael van der Vaart",
      "Enzo Scifo",
      "Hristo Bonev",
      "Oleksandr Zavarov",
      "Sepp Maier",
      "Juan Alberto Schiaffino",
      "Laurent Blanc",
      "Michel Preud'homme",
      "Dragan Stojkovic",
      "Gyula Grosics",
      "El Hadji Diouf",
      "Yoann Gourcuff",
      "Kolo Toure",
      "Djibril Cisse",
      "Ricardo Carvalho",
      "David Ginola",
      "Harald Schumacher",
      "Henrik Larsson",
      "Nani",
      "Diego Costa",
      "Gary Pallister",
      "Asamoah Gyan",
      "Gordon Strachan",
      "Dean Saunders",
      "Marc Overmars",
      "Gerhard Hanappi",
      "Mario Coluna",
      "Valery Voronin",
      "Youri Djorkaeff",
      "Sylvain Wiltord",
      "Frederic Kanoute",
      "Sandor Kocsis",
      "Ivan Rakitic",
      "Ronnie Hellstrom",
      "Wilfried Van Moer",
      "Sandro Mazzola",
      "Pat Jennings",
      "Franco Causio",
      "Rainer Bonhof",
      "Bruno Pezzey",
      "Ronald Koeman",
      "Juninho",
      "Philippe Albert",
      "Jean-Marie Pfaff",
      "Brian Laudrup",
      "Luca Toni",
      "Roberto Bettega",
      "Andrey Arshavin",
      "Lajos Tichy",
      "Juan Sebastian Veron",
      "Geoff Hurst",
      "Alessandro Altobelli",
      "Oliver Bierhoff",
      "Pauleta",
      "Virgil van Dijk",
      "Luis Enrique",
      "Milan Baros",
      "Papa Bouba Diop",
      "Christian Ziege",
      "Ludovic Giuly",
      "Cris",
      "Diego Godin",
      "Fernando Morientes",
      "Gaizka Mendieta",
      "Jamie Carragher",
      "Ivan Kolev",
      "Albert Shesternyov",
      "Alan Ball",
      "Ove Kindvall",
      "Wlodzimierz Lubanski",
      "Giancarlo Antognoni",
      "Liam Brady",
      "Glenn Hysen",
      "Clarence Seedorf",
      "Alessandro Nesta",
      "Chris Waddle",
      "Adriano",
      "Danny Blanchflower",
      "John Wark",
      "Raphael Varane",
      "Robinho",
      "Helmut Haller",
      "Gonzalo Higuain",
      "Ralf Edstrom",
      "Michel",
      "Jose Altafini",
      "Georgi Asparuhov",
      "Graeme Souness",
      "Hansi Muller",
      "Soren Lerby",
      "Stephane Chapuisat",
      "Rui Costa",
      "Filippo Inzaghi",
      "Luis Garcia",
      "Jozsef Bozsik",
      "Manuel Amoros",
      "Gianluca Zambrotta",
      "Alisson",
      "Fabrizio Ravanelli",
      "Jan Tomaszewski",
      "Bebeto",
      "Igor Chislenko",
      "Walter Schachner",
      "Pierre Littbarski",
      "Robert Prosinecki",
      "Julio Cesar Soares",
      "Erwin Vandenbergh",
      "Daniel Passarella",
      "Rui Patricio",
      "Jose Antonio Reyes",
      "Des Walker",
      "Maniche",
      "Junichi Inamoto",
      "Mark Hateley",
      "Dominique Rocheteau",
      "Julio Salinas",
      "Dries Mertens",
      "Eduard Streltsov",
      "Jef Jurion",
      "Dragoslav Sekularac",
      "Andrej Kvasnak",
      "Janos Gorocs",
      "Louis Pilot",
      "Mario Corso",
      "Billy Bremner",
      "Trevor Brooking",
      "Dudu Georgescu",
      "Zdenek Nehoda",
      "Morten Olsen",
      "Joao Resende Alves",
      "Oleksiy Mykhaylychenko",
      "Stefan Effenberg",
      "Roy Makaay",
      "Juan Roman Riquelme",
      "Martin Dahlin",
      "Jan Oblak",
      "Krasimir Balakov",
      "Gennaro Gattuso",
      "Didier Deschamps",
      "Gregory Coupet",
      "Jon Dahl Tomasson",
      "Paulo Dybala",
      "Isco",
      "Walter Zenga",
      "Agne Simonsson",
      "Jose Augusto",
      "Jose Augusto Torres",
      "Milan Galic",
      "Jesper Olsen",
      "Nils Liedholm",
      "Jurgen Croy",
      "Antonin Panenka",
      "Trevor Francis",
      "Dieter Muller",
      "Klaus Allofs",
      "Ronald de Boer",
      "Karoly Sandor",
      "Josip Skoblar",
      "Janos Farkas",
      "Angelo Domenghini",
      "Branko Oblak",
      "Juan Manuel Asensi",
      "Rene van de Kerkhof",
      "Horst Hrubesch",
      "Luis Arconada",
      "Vladimir Petrovic",
      "Bruno Conti",
      "Maxime Bossis",
      "Safet Susic",
      "Luis Fernandez",
      "Bixente Lizarazu",
      "Claudio Lopez",
      "Viliam Schrojf",
      "Ernst Ocwirk",
      "Hasan Sas",
      "Alen Boksic",
      "Gunnar Gren",
      "Harry Gregg",
      "Roberto Ayala",
      "Tony Yeboah",
      "Karl-Heinz Riedle",
      "Uli Hoeness",
      "Tiago Mendes",
      "Johnny Rep",
      "Jozef Mlynarczyk",
      "Valentin Ivanov",
      "Gianluca Pagliuca",
      "Marc Wilmots",
      "Socrates",
      "Packie Bonner",
      "Martin Peters",
      "Wlodzimierz Smolarek",
      "Glenn Hoddle",
      "Rai",
      "Adrian Mutu",
      "Jose Aguas",
      "Jack Charlton",
      "Ladislav Novak",
      "Luis del Sol",
      "Slava Metreveli",
      "Horst Nemec",
      "Franny Lee",
      "Mimis Domazos",
      "Nestor Combin",
      "Willi Schulz",
      "Yevgeniy Rudakov",
      "Antal Dunai",
      "Murtaz Khurtsilava",
      "Piet Keizer",
      "Tommy Gemmell",
      "Terry Cooper",
      "Jupp Heynckes",
      "Hans-Jurgen Kreische",
      "Ruud Geels",
      "Jean-Marc Guillou",
      "Barry Hulshoff",
      "Gordon McQueen",
      "Marian Masny",
      "Eric Gerets",
      "Manfred Kaltz",
      "Torbjorn Nilsson",
      "Gerard Janvion",
      "Herbert Prohaska",
      "Uli Stielike",
      "Dominique Bathenay",
      "Andras Toroczik",
      "Fernando Gomes",
      "Ladislav Vizek",
      "Hans-Peter Briegel",
      "Dimitris Saravakos",
      "Anatoliy Demyanenko",
      "Guido Buchwald",
      "Oleh Kuznetsov",
      "Roberto Mancini",
      "Rune Bratseth",
      "Flemming Povlsen",
      "Giuseppe Signori",
      "Thomas Hassler",
      "Zvonimir Boban",
      "Sonny Anderson",
      "Paulo Sousa",
      "Zlatko Zahovic",
      "Francesco Toldo",
      "Giovane Elber",
      "Mario Jardel",
      "Ruben Baraja",
      "Emerson (1976)",
      "Pablo Aimar",
      "Maicon Sisenando",
      "Laszlo Kubala",
      "Jim Baxter",
      "Pierre van Hooijdonk",
      "Finidi George",
      "Ricardo Zamora",
      "Jose Nasazzi",
      "Jose Santamaria",
      "Cesare Maldini",
      "Seol Ki-Hyeon",
      "Hidetoshi Nakata",
      "Koke",
      "Mahamadou Diarra",
      "Karel Poborsky",
      "Max Morlock",
      "Viv Anderson",
      "Kennet Andersson",
      "Anatoliy Byshovets",
      "Marcelo Salas",
      "Roger Piantoni",
      "Jose Luis Caminero",
      "Luis Fabiano",
      "Theodoros Zagorakis",
      "Antoni Ramallets",
      "Ivor Allchurch",
      "Viktor Ponedelnik",
      "Archie Gemmill",
      "Florea Dumitrache",
      "Heinz Flohe",
      "Roland Sandberg",
      "Klaus Fischer",
      "Arie Haan",
      "Joachim Streich",
      "Silviu Lung",
      "Didier Six",
      "Marco Tardelli",
      "Andreas Kopke",
      "Yordan Letchkov",
      "Dino Baggio",
      "John Jensen",
      "Cafu",
      "Charles Antenen",
      "Giampiero Boniperti",
      "Lennart Skoglund",
      "Colin McDonald",
      "Georgi Naydenov",
      "Karl Koller",
      "Milos Milutinovic",
      "Joaquin Peiro",
      "Erno Solymosi",
      "Peter Lorimer",
      "Sigfried Held",
      "Josip Katalinski",
      "Jurgen Grabowski",
      "Jurgen Sparwasser",
      "Manuel Bento",
      "Jerzy Gorgon",
      "Vladislav Bogicevic",
      "Francesco Graziani",
      "Willy van de Kerkhof",
      "Ramaz Shengelia",
      "Aleksandre Chivadze",
      "Steve Archibald",
      "Carlos Manuel Santos",
      "Frankie Vercauteren",
      "Thomas Ravelli",
      "Enzo Francescoli",
      "Lajos Detari",
      "Carlos Valderrama",
      "Oleg Protassov",
      "Pavlo Yakovenko",
      "Georges Grun",
      "Norman Whiteside",
      "Toni Polster",
      "Txiki Begiristain",
      "Emil Kostadinov",
      "Andreas Herzog",
      "Sinisa Mihajlovic",
      "Hakan Sukur",
      "Pep Guardiola",
      "Vitor Baia",
      "Victor Ikpeba",
      "Bernd Schneider",
      "Ebbe Sand",
      "Edmilson",
      "Jan Koller",
      "Juan Carlos Valeron",
      "Emmanuel Olisadebe",
      "Fabio Grosso",
      "Edison Mendez",
      "Shunsuke Nakamura",
      "Javier Saviola",
      "Ricardo Quaresma",
      "Mark van Bommel",
      "Eric Abidal",
      "Jaap Stam",
      "Vicente Rodriguez",
      "Dixie Dean",
      "Stefan Bozhkov",
      "Kees Rijvers",
      "Roger Marche",
      "Robert Jonquet",
      "Vujadin Boskov",
      "Bengt Gustavsson",
      "Campanal II",
      "Joseph Ujlaki",
      "Svatopluk Pluskal",
      "Thadee Cisowski",
      "Ake Johansson",
      "Karl Stotz",
      "Orvar Bergmark",
      "Igor Netto",
      "Jimmy McIlroy",
      "Joan Segarra",
      "Tommy Taylor",
      "Yuriy Voynov",
      "Bobby Smith",
      "Dezso Bundzsak",
      "Dumitru Macri",
      "Julio Botelho",
      "Manfred Kaiser",
      "Alberto Costa Pereira",
      "Andre Lerond",
      "Blagoje Vidinic",
      "Borivoje Kostic",
      "Germano de Figueiredo",
      "Norbert Eschmann",
      "Bretislav Dolejsi",
      "Erich Hof",
      "Pierre Bernard",
      "Rudolf Kucera",
      "Lucien Muller",
      "Miguel Montuori",
      "Pepillo II",
      "Titus Bubernik",
      "Coen Moulijn",
      "Jozef Adamec",
      "Metin Oktay",
      "Mikheil Meskhi",
      "Ole Madsen",
      "Antonio Angelillo",
      "Ezio Pascutti",
      "Bruno Nicole",
      "Harry Bild",
      "Nikola Kotkov",
      "Zygfryd Szo\u0142tysik",
      "Gernot Fraydl",
      "Johnny Giles",
      "Ken Jones",
      "Robert Herbin",
      "Angelo Sormani",
      "Giorgos Sideris",
      "Jean Djorkaeff",
      "Mirsad Fazlagic",
      "Tony Dunne",
      "Zvezdan Cebinac",
      "Francis Lee",
      "Giovanni Trapattoni",
      "Ivica Osim",
      "Jean Nicolay",
      "Gert Dorfel",
      "Gilbert Gress",
      "Ivan Curkovic",
      "Klaus Urbanczyk",
      "Jakob Kuhn",
      "John Greig",
      "Philippe Gondet",
      "Franz Hasil",
      "Jorn Bjerregaard",
      "Manuel Velazquez",
      "Martin Chivers",
      "Petar Zhekov",
      "Rinus Israel",
      "Willem van Hanegem",
      "Gerrie Muhren",
      "Emlyn Hughes",
      "Giorgio Chinaglia",
      "Pedro de Felipe",
      "Carles Rexach",
      "Jan Pivarnik",
      "Jaroslav Pollak",
      "Pierino Prati",
      "Robert Gadocha",
      "Vladimir Muntyan",
      "Colin Todd",
      "Cornel Dinu",
      "Anders Linderoth",
      "David Kipiani",
      "Ray Kennedy",
      "Benny Wendt",
      "Costica Stefanescu",
      "Don Givens",
      "Terry McDermott",
      "Anton Ondrus",
      "Frans Thijssen",
      "Kees Kist",
      "Santillana",
      "Steve Heighway",
      "Antonio Oliveira",
      "Francois Van Der Elst",
      "Leonid Buryak",
      "Rui Jordao",
      "Vahid Halilhodzic",
      "Ali Kemal Denizci",
      "Felix Magath",
      "Gaetano Scirea",
      "Marcel Raducanu",
      "Vasilis Hatzipanagis",
      "Jesus Zamora",
      "Tony Woodcock",
      "Antonio Maceda",
      "Frank Stapleton",
      "Rodion Camataru",
      "Frank Arnesen",
      "Rafael Gordillo",
      "Fernando Chalana",
      "Georgi Dimitrov",
      "Simon Tahamata",
      "Stoicho Mladenov",
      "Zlatko Vujovic",
      "Fedor Cherenkov",
      "Heinz Hermann",
      "Helmuth Duckadam",
      "Karl-Heinz Forster",
      "Ally McCoist",
      "Robby Langers",
      "Stelios Manolas",
      "Guy Hellers",
      "John Sivebaek",
      "Theo Snelders",
      "Bruno Martini",
      "Otto Konrad",
      "Paul McStay",
      "Sokol Kushta",
      "Gary Stevens",
      "Henrik Andersen",
      "Miodrag Belodedici",
      "Tanju Colak",
      "Andreas Thom",
      "Basile Boli",
      "Franck Sauzee",
      "Henrik Larsen",
      "Ivan Zamorano",
      "Jurgen Kohler",
      "Olaf Thon",
      "Rafael Martin Vazquez",
      "Thomas Doll",
      "Trifon Ivanov",
      "Ciro Ferrara",
      "Fernando Redondo",
      "Angelo Peruzzi",
      "Kubilay Turkyilmaz",
      "Antonios Nikopolidis",
      "Bent Skammelsrud",
      "Mario Basler",
      "Sergei Kiriakov",
      "Ciriaco Sforza",
      "Juan Esnaider",
      "Michalis Kapsis",
      "Nikos Machlas",
      "Radek Bejbl",
      "Rogerio Ceni",
      "Sergi Barjuan",
      "Patrick Berger",
      "Johan Micoud",
      "Ailton Goncalves",
      "Damiano Tommasi",
      "Samuel Kuffour",
      "Traianos Dellas",
      "Ivan Helguera",
      "Mauro Camoranesi",
      "Willy Sagnol",
      "Cristian Chivu",
      "Mista",
      "Yildiray Basturk",
      "Lucio",
      "Marcos Senna",
      "Nihat Kahveci",
      "Giourkas Seitaridis",
      "Younis Mahmoud",
      "Lajos Szucs",
      "Hatem Trabelsi",
      "Darko Kovacevic",
      "Angelos Charisteas",
      "Michel Salgado",
      "Yuri Zhirkov",
      "Peter Reid",
      "David O'Leary",
      "Vincent Candela",
      "Dion Dublin",
      "Ashley Young",
      "Pepe Reina",
      "Gylfi Sigurdsson",
      "Kevin Nolan",
      "Romelu Lukaku",
      "Gary Speed",
      "Alexandre Lacazette",
      "David Unsworth",
      "Stephane Ruffier",
      "Nigel Martyn",
      "Les Ferdinand",
      "Robbie Fowler",
      "Leighton Baines",
      "Brad Friedel",
      "David James",
      "Gonzalo Castro",
      "Nolberto Solano",
      "Bafetimbi Gomis",
      "Gus Poyet",
      "Tim Howard",
      "Dirk Kuyt",
      "Bernd Leno",
      "Mark Schwarzer",
      "Danny Murphy",
      "James Milner",
      "Robbie Keane",
      "Gary McAllister",
      "Oliver Baumann",
      "Edgar Davids",
      "Dan Petrescu",
      "Darren Bent",
      "Anthony Lopes",
      "Nicky Butt",
      "Steed Malbranque",
      "Yakubu Aiyegbeni",
      "Ian Harte",
      "Trevor Sinclair",
      "Gareth Barry",
      "Steve Mandanda",
      "Lee Bowyer",
      "Christian Gentner",
      "Raheem Sterling",
      "Salomon Kalou",
      "Teddy Sheringham",
      "Lars Stindl",
      "Branislav Ivanovic",
      "Daniel Caligiuri",
      "Antonio Valencia",
      "Thomas Sorensen",
      "Son Heung-Min",
      "Andre Ayew",
      "Chris Sutton",
      "Gary Cahill",
      "Naldo (1982)",
      "Ole Gunnar Solskjaer",
      "Sejad Salihovic",
      "Mikel Arteta",
      "Aaron Ramsey",
      "Theodor Gebre Selassie",
      "Cedric Carrasso",
      "Nolan Roux",
      "Mathieu Debuchy",
      "Robbie Earle",
      "Anthony Modeste",
      "Mark Viduka",
      "David Alaba",
      "Loic Remy",
      "Steve Watson",
      "Charles N'Zogbia",
      "Stefan Kiessling",
      "Klaas-Jan Huntelaar",
      "Emile Heskey",
      "Kieran Richardson",
      "Javier Hernandez",
      "Tim Flowers",
      "Jimmy Briand",
      "Max Kruse",
      "Shay Given",
      "Leon Osman",
      "Muzzy Izzet",
      "Nils Petersen",
      "Xherdan Shaqiri",
      "Joel Matip",
      "Kevin Trapp",
      "Simon Mignolet",
      "Paul Robinson (GK)",
      "Julien Feret",
      "Danijel Subasic",
      "Steve McManaman",
      "Kevin Volland",
      "Laurent Koscielny",
      "Raffael",
      "Matt Le Tissier",
      "Claudio Pizarro",
      "Peter Crouch",
      "Jussi Jaaskelainen",
      "Mevlut Erdinc",
      "Geoffrey Jourdren",
      "Ron-Robert Zieler",
      "Patrik Berger",
      "David Ospina",
      "Remy Cabella",
      "Kasper Schmeichel",
      "Paul Ince",
      "Gabby Agbonlahor",
      "James Beattie",
      "Jermaine Jenas",
      "Cheick Diabate",
      "Ugo Ehiogu",
      "Mark Noble",
      "Wahbi Khazri",
      "Aleksandar Kolarov",
      "Benoit Costil",
      "Kevin Phillips",
      "Yohan Cabaye",
      "Denis Irwin",
      "Ben Foster",
      "Rildo",
      "Tim Sherwood",
      "Darren Anderton",
      "Ryad Boudebouz",
      "Clint Dempsey",
      "Mark Bosnich",
      "Andre Schurrle",
      "Ilkay Gundogan",
      "Lukasz Piszczek",
      "Lukasz Fabianski",
      "Ivan Perisic",
      "Timo Werner",
      "Ian Walker",
      "Ralf Fahrmann",
      "Daniel Sturridge",
      "Yunus Malli",
      "Dennis Wise",
      "Tony Cottee",
      "Rene Adler",
      "Paolo Di Canio",
      "Lewis Holtby",
      "Simon Davies",
      "Florian Thauvin",
      "Neil Sullivan",
      "Matthew Taylor",
      "Seamus Coleman",
      "Kevin Davies",
      "Paulo Wanchope",
      "Oyvind Leonhardsen",
      "Joleon Lescott",
      "Oscar Wendt",
      "Alexander Meier",
      "Shaka Hislop",
      "Martin Harnik",
      "Diego Benaglio",
      "Paul Jones",
      "Don Hutchison",
      "Hermann Hreidarsson",
      "Andrej Kramaric",
      "Asmir Begovic",
      "Koo Ja-Cheol",
      "Adam Johnson",
      "Papiss Cisse",
      "David Dunn",
      "Morgan Sanson",
      "Gareth Southgate",
      "Paul Scharner",
      "Marcell Jansen",
      "Louis Saha",
      "Souleymane Camara",
      "Kevin Kilbane",
      "Andre Hahn",
      "Boudewijn Zenden",
      "Yossi Benayoun",
      "Craig Bellamy",
      "Christian Benteke",
      "Romain Hamouma",
      "Glen Johnson",
      "Marko Arnautovic",
      "Ruel Fox",
      "Ivica Olic",
      "Julian Brandt",
      "Robert Huth",
      "Christophe Jallet",
      "Jordan Henderson",
      "Shinji Okazaki",
      "Anthony Martial",
      "Craig Gardner"
    ]
  },
  "zonal": {
    "answers": [
      "Ince",
      "Cristiano Ronaldo",
      "Schweinstiger",
      "Salah",
      "Owen",
      "Henry",
      "Suarez",
      "Figo",
      "Pique"
    ],
    "columnOne": [
      "Man United",
      "100+ Premier League goals",
      "Barcelona"
    ],
    "rowOne": [
      "Liverpool",
      "Real Madrid",
      "World Cup winner"
    ]
  }
}

export default WebQuestionService
import React from 'react';
import GameTitle from '../GameTitle';
import '../Games.css'
import { GolazoProgressEnum } from '../GolazoProgress';
import { BaseGameProps  } from '../../types';
import GameFooter from '../GameFooter';
import shuffle from '../../utils/shuffle';

export interface ZonalProps extends BaseGameProps {
    setProgress: Function
    rowOne: string[]
    columnOne: string[]
    answers: string[]
}

function Zonal(props: ZonalProps) {
    const options: string[] = shuffle([...props.answers]) as string[]
    const [optionState, setOptionState]: [string[], Function] = React.useState([...options])
    const [selectedOption, setSelectedOption]: [number, Function] = React.useState(-1)

    const handleLockIn = () => {
      const total = props.answers.map((ans, indx) => ans === optionState[indx])
      console.log(total, props.answers, optionState)
      if (total.find((bool) => bool === false) !== undefined) {
        props.setProgress(GolazoProgressEnum.Wrong)
      } else {
        props.setProgress(GolazoProgressEnum.Correct)
      }
    }

    const showAnswers = () => {
      setOptionState([...props.answers])
    }

    const handleSelection = (indx: number) => {
      if (selectedOption !== -1) {
        const newOptionState = [...optionState]
        const tmp = optionState[selectedOption]
        newOptionState[selectedOption] = newOptionState[indx]
        newOptionState[indx] = tmp
        setSelectedOption(-1)
        setOptionState(newOptionState)
      } else {
        setSelectedOption(indx)
      }
    }

    const swappableGridSquare = (indx: number) => {
      let className = "swappable"
      if (props.status !== GolazoProgressEnum.Unanswered) {
        if (props.answers[indx] === optionState[indx]) {
          className += " correct-answer"
        } else {
          className += " incorrect-answer"
        }
      }
      return (
        <div className={className+(indx === selectedOption ? ' swappable-selected' : '')} onClick={() => handleSelection(indx)}>{optionState[(indx)]}</div>
      )
    }

    return (
        <div className='game-container'>
            <GameTitle title="Zonal" />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div className='zonal-grid'>
                <div className='key'></div>
                  {props.rowOne.map((v) => {
                    return(
                      <div className='key' key={v}>{v}</div>
                    )
                  })}
                  {props.columnOne.map((colValue, indx) => {
                    return(
                      <React.Fragment key={indx}>
                        <div className='key'>{colValue}</div>
                          {swappableGridSquare((indx * 3) + 0)}
                          {swappableGridSquare((indx * 3) + 1)}
                          {swappableGridSquare((indx * 3) + 2)}
                      </React.Fragment>
                    )
                  })}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-around' }}>
                {props.status === GolazoProgressEnum.Unanswered ? 
                <button style={{margin: 'auto', marginTop: '5vh'}} className='game-button' onClick={handleLockIn}>Lock In</button>
                :
                <GameFooter showAnswers={showAnswers} continue={() => props.gotoNext()} />
                }
            </div>
        </div>
    );
}

export default Zonal;
